<template>
  <section>
    <v-flex xs12 class="ctk-top-banner">
      <v-img
        v-if="info.banners[0]"
        :src="info.banners[0].img"
        class="ctk-top-banner"
        >
          <v-layout wrap hidden-sm-and-down>
            <v-flex md12 class="banner-right-div">
              <div class="banner-text-div">
                <p class="banner-text-en">SOLUTION</p>
                <div class="banner-text-line"></div>
                <p class="banner-text-zh">解决方案</p>
              </div>
            </v-flex>
          </v-layout>
        </v-img>
    </v-flex>
    <div class="div-1280 top-breadcrumb">
      <v-layout wrap>
        <v-flex md6 class="breadcrumb-left hidden-sm-and-down">睡眠环境改善案例</v-flex>
        <v-flex md6><v-breadcrumbs :items="breadcrumbs"></v-breadcrumbs></v-flex>
      </v-layout>
    </div>
    <div class="div-1280 ctk-container">
      <div class="page-title wow fadeInUp" id="cases-top">睡眠环境改善案例</div>
        <v-layout wrap v-if="cases">
          <v-flex xs12 md4 v-for="(item, ci) in cases" :key="item.key" :class="'cases-flex cases-flex' + ci % 3">
            <v-card flat tile class="cases-card">
              <a :href="'/case/' + item.id">
                <div class="cases-img wowd fadeInUp" data-wow-delay="0.2s"><img :src="item.img"/></div>
                <div class="cases-title wowd fadeInUp" data-wow-delay="0.4s">{{item.title}}</div>
              </a>
            </v-card>
          </v-flex>
        </v-layout>
      <template v-if="totalPage > 1">
        <div class="text-center">
          <v-pagination v-model="page" :length="totalPage"></v-pagination>
        </div>
      </template>
    </div>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 15,
    breadcrumbs: [
      { text: '首页', disabled: false, href: '/' },
      { text: '解决方案', disabled: false, href: 'javascript:void(0)' },
      { text: '睡眠环境改善案例', disabled: true }
    ],
    caseId: 18,
    cases: [],
    totalPage: 1,
    page: 1
  }),
  created () {
    document.title = '睡眠环境改善案例-好睡眠'
    document.querySelector('#menu_scheme').classList.add('v-btn--active')
    if (this.$route.params.page === undefined) {
      this.page = 1
    } else {
      this.page = parseInt(this.$route.params.page)
    }
    this.getCases(this.page, this.catId)
  },
  mounted () {
    this.$wow.init()
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    },
    page: {
      handler: function (val, oldVal) {
        this.$router.push({ name: 'cases', params: { page: val } })
      },
      deep: true
    },
    '$route' (to, from) {
      document.querySelector('.ctk-top-bar').scrollIntoView(true)
      this.getCases(this.page, this.catId)
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    getCases (page, cid) {
      this.https.get('cases', { page: page, cid: cid, pageSize: 12 }).then(response => {
        if (response.code === 0) {
          this.cases = response.data.data
          this.totalPage = response.data.last_page
          this.page = page
        }
      }).then(_ => {
        this.$wowd.init()
      })
    }
  }
}
</script>
<style lang="css">
  @import '~@/assets/css/cases.css';
</style>
